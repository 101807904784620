.login-container {
    background:#34475c;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .btn-login{
    border: none;
    width: 100%;
    background-color: #146262;
    color: #fff ;
    margin-top: 5px;
}
.btn-login:hover{
    border: none;
    background-color: rgba(9, 188, 138, .3);
    color:rgb(158, 205, 225) ;
}

.formulario-login{
    color: #032b2e;
}

.ant-form-item-label > label {
    color: #032b2e;
}

.login-title {
    text-align: center;
    margin-bottom: 24px;
  }

  .btn-link {
    color: #84e2d8;
    text-decoration: underline;
  }

  .btn-link.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover{
    color: #449bbb;
  }

  .formulario-login .ant-input-outlined {
    background: #113536;
  }