.logo {
    height: 6vmin;
    pointer-events: none;
  }

  @media screen and (max-width: 960px) {
    .logo {
      height: 7vmin;
      pointer-events: none;
     
    }
  }

  .userAvatar{
    word-break: normal;
  }