:root {
  /* Colores primarios */
  --dark-sky-blue: #74b3ceff;
  --steel-teal: #508991ff;
  --prussian-blue: #172a3aff;
  --warm-black: #004346ff;
  --mountain-meadow: #09bc8aff;

  --bright-navy-blue: #3e78b2ff;
  --cobalt-blue: #004ba8ff;
  --davys-grey: #4a525aff;
  --raisin-black: #24272bff;
  --rich-black-fogra-39: #07070aff;
  --principal-bg: #101b21;
}

* {
  font-family: 'Roboto',sans-serif;
  /* font-family: 'Trebuchet MS'; */
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--principal-bg)
}

#Dark {
  background-color: #282c34;
  color: #ffffff;
  min-height: 100vh;
}

#Light {
  background-color: #f2f4f6;
  color: #333333;
  min-height: 100vh;
}

.mt-20 {
  margin-top: 20px
}

.mt-50 {
  margin-top: 20px
}

.w-100{
  width: 100%;
}

.txt-12 {
  font-size: 12px;
}

.txt-22 {
  font-size: 22px;
}

.notification-custom {
  background-color: #144848;
  color: #ffffff;
  border: 2px solid #84e2d8;

}

.notification-custom .ant-notification-notice-with-icon .ant-notification-notice-description,
.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-close,
.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-message{
  color: rgb(221, 235, 228);
  font-weight: 700;
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-close:hover {
  color: skyblue;
  font-weight: 900;
  font-size: 16px;
}

.txt-center{
  text-align: center;
}
