.contador{

    font-family: cursive;
    text-align: center;
    margin-top: 20px;
    font-size: medium;
    font-weight: 800;
    display: flex;
    justify-content: right;
    margin-left: auto;
    margin-right: auto;
    color:rgb(175, 149, 149);
}
.radio-fase{
    color: #FFFFFF;
    text-align: center;
}