.cardsPuntos{
    margin-top: 10px;    
}

.cardsPuntos .ant-card-head{
    background-color: rgba(0, 67, 70, 1);
    color: #FFFFFF;
    text-align: center;
    height: 15px;
}

.cardsPuntos .ant-card-body{
    background-color: rgba(80, 137, 145, 1);
    color: #FFFFFF;
    text-align: center;
    font-size: 1.6em;
}

.tablaPuntos .ant-table{
    background-color: rgba(0, 67, 70, .8);
    color: #FFFFFF;
}

.tablaPuntos .ant-table-thead > tr > th{
    background-color: rgba(23, 42, 58, 0.8);
    color: #FFFFFF;
    text-align: center;
}

.tablaPuntos .ant-table-tbody > tr.ant-table-row:hover > td{
    background-color: rgba(80, 137, 145, .7);
    color: #FFFFFF;
}

tr.ant-table-expanded-row > td, tr.ant-table-expanded-row > td {
    background-color: rgba(9, 188, 138, .7);
}

.tablaPuntos  > tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
    background: #297c93;
}

button.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed{
    background-color: rgba(7, 7, 10, 1);
}

button.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded{
    background-color: rgba(0, 75, 168, 1);
}

.ant-card-body {
    padding: 5px;
}

.ant-card {
    background: none;
}

.btn-config {
    text-align: center   ;
}


.mod-partipant .ant-modal-header .ant-modal-title{
    text-align: center;
    font-weight: 900;
    font-size: 18px;
}


.ant-modal-close-x {
    color:rgba(116, 179, 206, 1) ;
}

.ant-input-number, .ant-input-number-input{
    background-color: rgba(0, 67, 70, 1);
    color: rgba(116, 179, 206, 1);
    font-weight: bolder;
    font-size: 18px;
    text-align: center;
}


.ant-input-number-handler-wrap{
    background-color: transparent;
}

.btn-danger{
    background-color: rgba(144, 6, 6, 0.8);
    color: #FFFFFF;
}

.btn-danger:hover{
    background-color: rgba(0, 67, 70, 1);
    color: rgba(116, 179, 206, 1);
}

.btn-success{
    background-color: rgba(9, 188, 138, 1);
    color: #FFFFFF;
}

.btn-success:hover{
    background-color: rgba(0, 67, 70, 1);
    color: rgba(116, 179, 206, 1);
}

.btn-warning{
    background-color: rgb(132, 90, 49);
    color: #FFFFFF;
}

.btn-warning:hover{
    background-color: rgba(0, 67, 70, 1);
    color: #FFFFFF;
}

.btn-acciones{
    background-color: #508991;
    color: #FFFFFF;
}

.btn-acciones:hover{
    background-color: #fff; 
    color: #1C4841;
}

.radio-fase{
    color: #FFFFFF;
    text-align: center;
}

.ant-radio-wrapper{
    color: #FFFFFF;
}


@media screen and (max-width: 680px) {
    .tablaPuntos .ant-table{
        font-size: 10px;
    }
    .cardsPuntos .ant-card-head{
        font-size: 14px;
    }

    .tablaPuntos .ant-table-tbody {
        font-size: 10px;
    }
    
    .extraData{
        font-size: 10px;
    }
  }