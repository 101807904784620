.wip-container {
    min-height: 60vh;
    text-align: center;
    padding: 20px;
  }
  
  .wip-content {
    background: #185c6988;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .wip-icon {
    font-size: 48px;
    color: #1890ff;
    margin-bottom: 16px;
  }
  