.visitante{
    text-align: right;
}

.contenedorPartidos{
  margin: 0;
}

.card-header {
    font-size: 12px;
    text-align: center;
    padding-top: 5px;
    margin-top: 10px;
    border-top: 1px solid rgb(53, 60, 71);
  }

  .score-col{
    text-align: center;
  }

  .score{
    font-size: 20px;
    color:aqua;
    text-align: center;
  }