.contenedorOpciones{
    margin-top: 10px;
    text-align: center;
}

.btn-reglas{
    border: none;
    background-color: rgba(80, 137, 145, .2);
    color:rgba(116, 179, 206, 1) ;
    margin-top: 5px;
}
.btn-reglas:hover{
    border: none;
    background-color: rgba(9, 188, 138, .3);
    color:rgba(116, 179, 206, 1) ;
}

.txt-modal-info{
    color: #fff;
    text-align: justify;;
}
.txt-modal-info > h1 {
    color: #fff;
    text-align: center;
}

.ant-btn:focus, .ant-btn:active {
    background-color: rgba(9, 188, 138, .3);
    color:rgba(116, 179, 206, 1) ;
}